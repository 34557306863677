ul.pagination {
	margin: 0;
}

canvas {
	width: 100% !important;
}

.puntos-suspensivos {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

body {
	background: url(../../img/bg.png);
	background-color: #e9eaed;
}

.alert {
	box-shadow: 0 0 16px #FFF;
}

table.table tbody tr td, table.table tbody tr th {
	vertical-align: middle;
}

table.table thead tr th {
	text-transform: uppercase;
}

.form-group.required .control-label:after {
	content: " *";
	color: red;
}

.btn, .panel-title, .modal-title {
	font-weight: 700;
	text-transform: uppercase;
}
